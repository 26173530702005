import React from 'react';
import Container from 'layouts/Container';
import styled from 'styled-components';
import Logo from 'assets/images/gdmc_logo.svg';
import { useTranslation } from 'react-i18next';
import { Colors, Queries } from 'styles';
import { useStaticQuery, graphql } from 'gatsby';
import { getLangData } from 'utils';

const FooterContact = styled.div`
  width: 100%;
  background-color: ${Colors.gdmc_blue};
  color: white;
  padding: 1.25rem 1rem;
  text-align: center;
  font-size: 1.125rem;

  @media ${Queries.md} {
    padding: 2rem 1rem;
    font-size: 1.5rem;
  }
`;
const FooterContainer = styled.div`
  width: 100%;
  background-color: ${Colors.bg_lite_grey};
`;
const FooterContent = styled.div`
  padding: 1.5rem 1.5rem 1rem;

  .main {
    display: flex;
  }

  .logo {
    display: none;
    margin-right: 2.5rem;

    @media ${Queries.md} {
      display: block;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    @media ${Queries.md} {
      flex-direction: row;
    }
  }

  .footerSection {
    margin-right: 1rem;
    margin-bottom: 1rem;
    .title {
      font-size: 0.875rem;
      font-weight: bold;
      margin: 0 0 0.25rem 0;
    }
    .content {
      font-size: 0.75rem;
    }

    @media ${Queries.lg} {
      margin-right: 1.5rem;

      .title {
        font-size: 1.125rem;
      }

      .content {
        font-size: 0.875rem;
      }
    }
  }

  .copyright {
    color: ${Colors.gdmc_blue};
    padding: 1rem 0;
  }
`;

const Footer = () => {
  const res = useStaticQuery(graphql`
    query FooterQuery {
      allPrismicHome {
        edges {
          node {
            lang
            data {
              contact {
                contact_info {
                  text
                }
                contact_type {
                  text
                }
              }
              service_hours {
                closes {
                  text
                }
                day {
                  text
                }
                opens {
                  text
                }
              }
              address {
                html
              }
            }
          }
        }
      }
    }
  `);
  const { i18n, t } = useTranslation();
  const data = getLangData(res.allPrismicHome.edges, i18n.language);
  return (
    <>
      <FooterContact>
        <strong>{t('need_help')}</strong> {t('contact_us_at')} +62 21 29912131
      </FooterContact>
      <FooterContainer>
        <Container>
          <FooterContent>
            <div className="main">
              <img
                className="logo"
                src={Logo}
                width="150"
                height="54"
                alt="GDMC"
              />
              <div className="contentContainer">
                <div className="footerSection">
                  <h3 className="title">{t('location')}</h3>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: data.address.html }}
                  />
                </div>
                <div className="footerSection">
                  <h3 className="title">{t('service_hours')}</h3>
                  <div className="content">
                    {data.service_hours.map((item, idx) => (
                      <div key={idx}>
                        {item.opens.text
                          ? `${item.opens.text} - ${item.closes.text} ${item.day.text}`
                          : `${item.day.text} - ${t('closed')}`}
                      </div>
                    ))}
                    {`(${t('closed_on_holidays')})`}
                  </div>
                </div>
                <div className="footerSection">
                  <h3 className="title">{t('contact')}</h3>
                  <div className="content">
                    {data.contact.map((item, idx) => (
                      <div key={idx}>
                        {`${item.contact_type.text}: ${item.contact_info.text}`}
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              © {new Date().getFullYear()} Good Doctors Medical Centre
            </div>
          </FooterContent>
        </Container>
      </FooterContainer>
    </>
  );
};

export default Footer;
