import React, { useContext } from 'react';
import LangIcon from 'assets/images/icon_lang.svg';
import PhoneIcon from 'assets/images/icon_phone.svg';
import InstaIcon from 'assets/images/icon_ig.svg';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { LocationContext } from 'layouts/Layout';

const LangContainer = styled.div`
  padding-top: 0.75rem;

  @media ${Queries.md} {
    padding-top: 0;
  }

  .icon {
    margin-right: 0.375rem;
  }

  .langSelect {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    @media ${Queries.md} {
      justify-content: flex-end;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      @media ${Queries.md} {
        display: none;
      }
    }

    .langLinks {
      display: flex;
      align-items: center;
      a {
        font-size: 0.75rem;
        display: block;
        padding: 0 0.25rem;
        &:first-child {
          border-right: 1px solid ${Colors.dark_grey};
        }
      }
    }
  }
  .contact {
    display: none;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: ${Colors.gdmc_blue};
    padding-top: 0.25rem;

    .block {
      display: flex;
      align-items: center;
      padding-right: 0.75rem;

      & > a {
        text-decoration: none;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    @media ${Queries.md} {
      display: flex;
    }
  }
`;

const LangContact = ({ contact = '+62 21 29912131' }) => {
  const location = useContext(LocationContext);
  let pathname = location.pathname.replace(/\/id\/*/, '/');
  return (
    <LangContainer>
      <div className="langSelect">
        <div className="left">
          <img
            className="icon"
            src={LangIcon}
            width="24"
            height="24"
            alt="language"
          />
          <div className="langLinks">
            <a href={`${pathname}`}>English</a>
            <a href={`/id${pathname}`}>Bahasa Indonesia</a>
          </div>
        </div>
        <div className="right">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/gooddoctor.jakarta/"
          >
            <img
              className="icon"
              src={InstaIcon}
              width="24"
              height="24"
              alt="Instagram"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/gooddoctor.jakarta/"
          >
            gooddoctor.jakarta
          </a>
        </div>
      </div>
      <div className="contact">
        <div className="block">
          <img
            className="icon"
            src={PhoneIcon}
            width="24"
            height="24"
            alt="contact"
          />
          <div className="contactText">{contact}</div>
        </div>
        <div className="block">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/gooddoctor.jakarta/"
          >
            <img
              className="icon"
              src={InstaIcon}
              width="24"
              height="24"
              alt="language"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/gooddoctor.jakarta/"
          >
            gooddoctor.jakarta
          </a>
        </div>
      </div>
    </LangContainer>
  );
};

export default LangContact;
