import React from 'react';
import styled from 'styled-components';
import { Breakpoints, Queries } from 'styles';

const ContainerStyle = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  @media ${Queries.md} {
    max-width: ${Breakpoints.md};
  }
  @media ${Queries.lg} {
    max-width: ${Breakpoints.lg};
  }
  @media ${Queries.xl} {
    max-width: ${Breakpoints.xl};
  }
`;

const Container = ({ children }) => {
  return <ContainerStyle>{children}</ContainerStyle>;
};

export default Container;
