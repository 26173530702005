import React from 'react';
import Helmet from 'react-helmet';
import GlobalStyles from 'styles/GlobalStyles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'i18n';
import 'normalize.css';

export const LocationContext = React.createContext(null);

const Layout = ({ location, children }) => {
  return (
    <LocationContext.Provider value={location}>
      <Helmet
        link={[
          {
            rel: 'stylesheet',
            href:
              'https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap',
          },
        ]}
      />
      <GlobalStyles />
      <Header />
      {children}
      <Footer />
    </LocationContext.Provider>
  );
};

export default Layout;
