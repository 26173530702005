import { createGlobalStyle } from 'styled-components';
import { Colors } from 'styles';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: ${Colors.dark_grey};
  }
  body {
    min-height: 100%;
    background-color: ${Colors.bg_lite_grey};
  };
  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
  };
  ul, ol {
    padding-inline-start: 1.5rem;
  };
  a {
    color: ${Colors.gdmc_blue};

    &:hover {
      color: ${Colors.blue_lite};
    }
  }
  img {
    display: block;
  }
  #___gatsby, #gatsby-focus-wrapper {
    overflow: auto;
    width: 100%;
    height: 100%;
  };
`;
