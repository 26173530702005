import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'locales/en';
import id from 'locales/id';

const options = {
  order: ['path'],
  lookupFromPathIndex: 0,
  checkWhitelist: true,
};

const resources = {
  en: {
    translation: en,
  },
  id: {
    translation: id,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: options,
    resources,
    fallbackLng: 'en',
    whitelist: ['en', 'id'],
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
