import React from 'react';
import Container from 'layouts/Container';
import HeaderNav from 'components/HeaderNav';
import IsOpen from 'components/IsOpen';
import LangContact from 'components/LangContact';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Colors, Queries } from 'styles';
import Logo from 'assets/images/gdmc_logo.svg';
import { useTranslation } from 'react-i18next';

const HeaderContainer = styled.header`
  width: 100%;
  background-color: ${Colors.bg_lite_grey};
`;

const HeaderContent = styled.div`
  padding: 0.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${Queries.md} {
    flex-direction: row;
  }

  .left {
    display: flex;
    align-items: flex-end;

    .logo {
      display: block;
      margin-right: 0.5rem;
      width: 120px;
      height: 44px;

      @media ${Queries.md} {
        width: 150px;
        height: 54px;
      }
    }
  }
`;

// Logo ratio is 2.76:1

const Header = () => {
  const { i18n } = useTranslation();
  const langStr = i18n.language === 'en' ? '' : i18n.language;
  return (
    <HeaderContainer>
      <Container>
        <HeaderContent>
          <div className="left">
            <Link to={`/${langStr}`}>
              <img className="logo" src={Logo} width="150" height="54" alt="" />
            </Link>
            <IsOpen />
          </div>
          <div className="right">
            <LangContact />
          </div>
        </HeaderContent>
      </Container>
      <HeaderNav />
    </HeaderContainer>
  );
};

export default Header;
