const path = require(`path`);

const defaultLang = 'en-us';

const contentTypes = {
  SINGLE: 'Single',
  REPEAT: 'Repeatable',
};

// Update this everytime a new content type is added on Prismic
const allPageTypes = [
  {
    name: 'Home',
    navTitle: 'home',
    type: contentTypes.SINGLE,
  },
  {
    name: 'Package',
    navTitle: 'care_packages',
    type: contentTypes.REPEAT,
  },
  {
    name: 'Gallery',
    navTitle: 'gallery',
    type: contentTypes.SINGLE,
  },
  {
    name: 'Services',
    navTitle: 'services',
    type: contentTypes.SINGLE,
  },
  {
    name: 'Custom',
    navTitle: 'custom',
    type: contentTypes.REPEAT,
  },
];

exports.allPageTypes = allPageTypes;

// Return whether this page is single or repeatable
function getPageType(pageName) {
  const foundPage = allPageTypes.find(
    (pageType) => pageType.name.toLowerCase() === pageName.toLowerCase(),
  );
  return foundPage ? foundPage : null;
}

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
}

// Build the graphQL query string based on allPageTypes
exports.buildQueryStr = function() {
  let queryStr = '{';
  for (let pageType of allPageTypes) {
    queryStr = queryStr.concat(`
      allPrismic${pageType.name} {
        edges {
          node {
            lang
            uid
            type
            data {
              title {
                text
              }
            }
          }
        }
      }
    `);
  }
  queryStr = queryStr.concat('}');
  return queryStr;
};

// Dynamically create pages with the correct template
exports.makePages = function(data, createPage) {
  for (let queryType in data) {
    const { edges } = data[queryType];
    for (let edge of edges) {
      const { node } = edge;
      const langPath = node.lang === defaultLang ? '' : `${node.lang}`;
      const pageType = getPageType(node.type);
      if (pageType.type === contentTypes.SINGLE) {
        const parentPath = node.type === 'home' ? '' : `${node.type}`;
        createPage({
          path: `${langPath}/${parentPath}`,
          component: path.resolve(
            `./src/templates/${capitalize(node.type)}.jsx`,
          ),
          context: {
            lang: node.lang,
            uid: node.uid,
            title: pageType.navTitle,
          },
        });
      } else {
        const pageTypePath = node.type === 'custom' ? '' : `/${node.type}`;
        createPage({
          path: `${langPath + pageTypePath}/${node.uid}`,
          component: path.resolve(
            `./src/templates/${capitalize(node.type)}.jsx`,
          ),
          context: {
            lang: node.lang,
            uid: node.uid,
            title: pageType.navTitle,
            pageTitle: node.data.title.text,
          },
        });
      }
    }
  }
};
