export const Colors = {
  gdmc_orange: '#f79824',
  gdmc_blue: '#015CAB',
  bg_lite: '#fafcff',
  bg_lite_grey: '#f0f0f0',
  error_red: '#d42a2a',
  success_green: '#1eb060',
  dark_grey: '#242424',
  blue_lite: '#0e7bc4',
  grey_lite: '#dedede',
  shadow: 'rgba(0, 0, 0, 0.75)',
  shadow_lite: 'rgba(0, 0, 0, 0.25)',
};

export const Breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const Queries = {
  sm: `(min-width: ${Breakpoints.sm})`,
  md: `(min-width: ${Breakpoints.md})`,
  lg: `(min-width: ${Breakpoints.lg})`,
  xl: `(min-width: ${Breakpoints.xl})`,
};
