import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Container from 'layouts/Container';
import { Link } from 'gatsby';
import { Colors, Queries } from 'styles';
import { useStaticQuery, graphql } from 'gatsby';
import { allPageTypes } from '../../gatsby-helpers';
import MenuIcon from 'assets/images/icon_menu.svg';
import CloseIcon from 'assets/images/icon_close.svg';
import { useTranslation } from 'react-i18next';

const NavContainer = styled.div`
  width: 100%;
  background-color: ${Colors.gdmc_orange};
`;

const Nav = styled.nav`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${Colors.gdmc_orange};
  width: 100%;
  height: 100%;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  padding: 2.5rem 1.5rem;

  .link,
  .parentLink {
    position: relative;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: 0.2s ease-in-out color;

    &:hover,
    &.active {
      color: ${Colors.gdmc_blue};
    }
  }

  .link,
  .parentLinkContainer {
    position: relative;
    margin: 1rem 0;
  }

  .link:hover::after {
    transform: scaleY(1);
  }

  .link::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.5rem;
    width: 100%;
    height: 4px;
    background-color: ${Colors.gdmc_blue};
    transition: 0.2s ease-in-out transform;
    transform: scaleX(0);
  }

  .parentLink {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline: none;
    padding-right: 1.25rem;

    &::after {
      content: '▼';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
    }
  }

  .subLinks {
    display: none;
    z-index: 3;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 150%;
    padding: 1rem;
    background-color: ${Colors.gdmc_blue};
    flex-direction: column;
    min-width: max-content;
    border-radius: 3px;

    &.active {
      display: flex;
    }
  }

  .subLink {
    color: white;
    text-decoration: none;
    padding: 0.25rem 0;
    margin-bottom: 0.5rem;
    transition: 0.2s ease-in-out color;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: ${Colors.gdmc_orange};
    }
  }

  @media ${Queries.md} {
    flex-direction: row;
    position: relative;
    width: auto;
    height: auto;
    opacity: 1;
    visibility: visible;
    padding: 1.5rem 1.75rem;

    .link,
    .parentLinkContainer {
      margin: 0;
      margin-right: 2rem;
    }

    .subLinks {
      left: -1rem;
      transform: none;
    }

    .subLink {
      text-align: left;
    }
  }
`;

const MenuBtnContainer = styled.div`
  z-index: 2;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  position: ${(props) => (props.isOpen ? 'fixed' : 'relative')};
  top: 0;
  right: 0;

  @media ${Queries.md} {
    display: none;
    margin: 0;
  }
`;

const MenuBtn = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: 'center';
  justify-content: 'center';
  border: none;
  background: transparent;
  padding: 0;

  & > img {
    width: 100%;
    height: 100%;

    &.hide {
      display: none;
    }
  }

  @media ${Queries.md} {
    display: none;
  }
`;

const generateNavLinks = (edges, lang) => {
  const language = lang === 'en' ? 'en-us' : lang;
  const pages = edges.filter(
    (edge) => edge.node.context && edge.node.context.lang === language,
  );
  const navLinks = [];
  for (let page of pages) {
    if (page.node.context.title === 'custom') {
      continue; // don't display custom pages in nav
    } else if (!page.node.context.pageTitle) {
      navLinks.push({
        title: page.node.context.title,
        path: page.node.path,
      });
    } else {
      const foundNav = navLinks.find(
        (link) => link.title === page.node.context.title,
      );
      if (!foundNav) {
        navLinks.push({
          title: page.node.context.title,
          subLinks: [
            { title: page.node.context.pageTitle, path: page.node.path },
          ],
        });
      } else {
        foundNav.subLinks.push({
          title: page.node.context.pageTitle,
          path: page.node.path,
        });
      }
    }
  }
  return navLinks;
};

const HeaderNav = () => {
  const { i18n, t } = useTranslation();
  const res = useStaticQuery(graphql`
    query SitePagesQuery {
      allSitePage {
        edges {
          node {
            context {
              lang
              uid
              title
              pageTitle
            }
            path
          }
        }
      }
    }
  `);
  const [isOpen, setIsOpen] = useState(false);
  const [activeParent, setActiveParent] = useState('');
  useEffect(() => {
    const handler = (e) => {
      if (activeParent !== '') {
        setActiveParent('');
      }
    };
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [activeParent]);
  return (
    <NavContainer>
      <Container>
        <MenuBtnContainer isOpen={isOpen}>
          <MenuBtn onClick={() => setIsOpen(!isOpen)}>
            <img
              className={isOpen ? 'hide' : ''}
              src={MenuIcon}
              alt="open menu"
            />
            <img
              className={!isOpen ? 'hide' : ''}
              src={CloseIcon}
              alt="close menu"
            />
          </MenuBtn>
        </MenuBtnContainer>
        <Nav isOpen={isOpen}>
          {generateNavLinks(res.allSitePage.edges, i18n.language).map(
            (navLink, idx) => {
              if (!navLink.subLinks) {
                return (
                  <Link className="link" to={navLink.path} key={idx}>
                    {t(navLink.title)}
                  </Link>
                );
              } else {
                return (
                  <div key={idx} className="parentLinkContainer">
                    <button
                      onClick={() => setActiveParent(navLink.title)}
                      className={`parentLink ${
                        navLink.title === activeParent ? 'active' : ''
                      }`}
                    >
                      {t(navLink.title)}
                    </button>
                    <div
                      className={`subLinks ${
                        navLink.title === activeParent ? 'active' : ''
                      }`}
                    >
                      {navLink.subLinks
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((subLink, idx) => (
                          <Link key={idx} className="subLink" to={subLink.path}>
                            {subLink.title}
                          </Link>
                        ))}
                    </div>
                  </div>
                );
              }
            },
          )}
        </Nav>
      </Container>
    </NavContainer>
  );
};

export default HeaderNav;
