import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { getLangData } from 'utils';
import { Colors, Queries } from 'styles';

const IsOpenContainer = styled.div`
  .indicator {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-bottom: 0.25rem;

    .circle {
      width: 12px;
      height: 12px;
      background-color: ${(props) =>
        props.isOpen ? Colors.success_green : Colors.error_red};
      border-radius: 6px;
      margin-right: 0.25rem;
    }

    .openText {
      color: ${Colors.error_red};
      text-transform: uppercase;
      font-size: 0.875rem;

      &.isOpen {
        color: ${Colors.success_green};
      }

      @media ${Queries.md} {
        font-size: 1rem;
      }
    }

    .openHoursText {
      font-size: 0.75rem;
      color: ${Colors.dark_grey};

      @media ${Queries.md} {
        font-size: 0.875rem;
      }
    }
  }

  .location {
    font-size: 0.75rem;
  }
`;

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const convert24HrToNum = (hourStr) => {
  return hourStr.split(':')[0];
};

const IsOpen = () => {
  const res = useStaticQuery(graphql`
    query ServiceHoursQuery {
      allPrismicHome {
        edges {
          node {
            data {
              service_hours {
                opens {
                  text
                }
                day {
                  text
                }
                closes {
                  text
                }
              }
              google_maps_location {
                url
              }
            }
            lang
          }
        }
      }
    }
  `);
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const data = getLangData(res.allPrismicHome.edges, i18n.language);

  const getTodayServiceHours = useCallback(
    (data) => {
      const date = new Date();
      const today = date.getDay();
      return data.find(
        (item) => item.day.text.toLowerCase() === t(days[today]).toLowerCase(),
      );
    },
    [t],
  );

  const displayHours = useCallback(
    (data) => {
      const { opens, closes } = getTodayServiceHours(data);
      if (opens?.text && closes?.text) {
        return `(${opens.text} - ${closes.text})`;
      }
      return '';
    },
    [getTodayServiceHours],
  );

  const checkIfOpen = useCallback(
    (data) => {
      const todaysData = getTodayServiceHours(data);
      const date = new Date();
      const currHour = date.getHours();
      const openHour = convert24HrToNum(todaysData.opens.text);
      const closeHour = convert24HrToNum(todaysData.closes.text);
      if (currHour >= openHour && currHour < closeHour) {
        return true;
      }
      return false;
    },
    [getTodayServiceHours],
  );

  useEffect(() => {
    setIsOpen(checkIfOpen(data.service_hours));
  }, [data, checkIfOpen]);

  return (
    <IsOpenContainer isOpen={isOpen}>
      <div className="indicator">
        <div className="circle" />
        <div className={`openText ${isOpen ? 'isOpen' : ''}`}>
          {isOpen ? t('open_now') : t('closed_now')}{' '}
          <span className="openHoursText">
            {displayHours(data.service_hours)}
          </span>
        </div>
      </div>
      <div className="location">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={data.google_maps_location.url}
        >
          Rasuna Epicentrum Complex
        </a>
      </div>
    </IsOpenContainer>
  );
};

export default IsOpen;
